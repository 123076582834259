<template>
  <v-footer class="white">
    <v-container class="footer-container pa-0 pt-6 pa-sm-auto">
      <div
        class="social-row secondary d-flex pa-3 px-sm-5 rounded justify-space-between align-center"
      >
        <div>
          <img
            class="logo logo-desktop d-none d-sm-flex"
            :src="logoDesktop ? logoDesktop : '/logo/logo.svg'"
            alt="Logo Tigros"
            :style="{ height: '60px' }"
          />
          <img
            class="logo logo-mobile d-flex d-sm-none"
            :src="logoMobile ? logoMobile : '/logo/logo-mobile.svg'"
            alt="Logo Tigros"
            :style="{ height: '60px' }"
          />
        </div>
        <div class="d-flex align-center social-icon-container">
          <a href="https://www.facebook.com/Tigros/" target="_blank">
            <v-img
              class="social-icon facebook"
              src="/img_layout/footer/facebook.png"
              alt="facebook"
            />
          </a>
          <a href="https://www.instagram.com/tigros_official/" target="_blank">
            <img
              class="social-icon instagram"
              src="/img_layout/footer/instagram.png"
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/5337854?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A5337854%2Cidx%3A2-1-4%2CtarId%3A1457971529980%2Ctas%3ATigros"
            target="_blank"
          >
            <img
              class="social-icon linkedin"
              src="/img_layout/footer/linkedin.png"
              alt="linkedin"
            />
          </a>
          <a href="https://www.youtube.com/user/Tigrosspa" target="_blank">
            <img
              class="social-icon youtube"
              src="/img_layout/footer/youtube.png"
              alt="youtube"
            />
          </a>
        </div>
      </div>
      <v-row class="py-5">
        <v-col cols="12" md="7">
          <v-row
            no-gutters
            v-if="links && $vuetify.breakpoint.smAndUp"
            justify="space-between"
          >
            <!-- custom layout for column with "socials" CSS class, showing button for app download -->
            <!-- "socials" column not rendered on mobile app -->
            <v-col
              v-for="(link, index) in links"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              class="flex-column justify-start align-center align-md-start"
              :class="isCordovaPlatform(link.classes) ? 'd-none' : 'd-flex'"
            >
              <h4
                :class="
                  isSocialLinksColumn(link.classes)
                    ? 'mt-4 mt-sm-0 primary--text text-uppercase text-body-2 font-weight-bold text-center text-sm-left'
                    : 'primary--text text-uppercase text-center font-weight-bold text-sm-left mt-4'
                "
              >
                {{ link.title }}
              </h4>
              <div class="d-flex flex-column align-center align-md-start">
                <a
                  v-for="child in link.children"
                  :key="child.ID"
                  :href="child.url"
                  class="primary--text text-decoration-none my-1"
                  :class="[child.classes, { 'no-evt': index == 2 }]"
                  :target="child.target || '_self'"
                  @click="clicked"
                >
                  <span :class="child.classes">{{ child.title }}</span>
                </a>
              </div>
            </v-col>
          </v-row>
          <div v-else-if="links && $vuetify.breakpoint.xsOnly">
            <v-expansion-panels accordion flat tile :focusable="selectedTab">
              <v-expansion-panel v-for="(link, index) in links" :key="index">
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                  </template>
                  <div
                    class="primary--text font-weight-bold"
                    v-html="link.title"
                  />
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-2">
                  <div class="d-flex flex-column">
                    <a
                      v-for="child in link.children"
                      :key="child.ID"
                      :href="child.url"
                      class="primary--text text-decoration-none my-1"
                      :class="[child.classes, { 'no-evt': index == 2 }]"
                      :target="child.target"
                      @click="clicked"
                    >
                      <span :class="child.classes">{{ child.title }}</span>
                    </a>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="secondary pa-3 d-flex primary--text download-app-block">
            <img src="/img_layout/footer/app.png" alt="Immagine app" />
            <div class="ml-3">
              <span class="d-block font-weight-bold">{{
                $t("footer.downloadApp")
              }}</span>
              <a
                href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.tigros"
                target="_blank"
              >
                <img
                  class="d-block my-2"
                  src="/img_layout/footer/google-play.png"
                  alt="Immagine google play"
                />
              </a>
              <a
                href="https://geo.itunes.apple.com/it/app/tigros/id1055600644?mt=8"
                target="_blank"
              >
                <img
                  class="d-block"
                  src="/img_layout/footer/app-store.png"
                  alt="Immagine app store"
                />
              </a>
            </div>
          </div>
          <v-btn
            @click="clicked"
            href="/area-dipendenti/"
            depressed
            color="primary"
            block
            class="mt-6"
            outlined
            large
          >
            <v-icon class="mr-3">$profile</v-icon>
            {{ $t("footer.employeeAreaBtn") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="white" />
      <div
        class="mt-4 mb-12 d-flex flex-column flex-lg-row align-center justify-space-between"
      >
        <div class="order-2 order-lg-1 mt-3 mt-lg-0">
          <span class="copyright text-center primary--text text-caption">
            {{
              $t("footer.copyright", {
                currentYear: new Date().getFullYear()
              })
            }}
          </span>
          <i18n
            path="footer.version"
            tag="span"
            class="primary--text text-caption"
          >
            {{ version }}
          </i18n>
        </div>
        <PrivacyLinks></PrivacyLinks>
      </div>
    </v-container>
  </v-footer>
</template>
<style lang="scss">
.footer-container {
  border-top: 1px solid var(--v-grey-lighten2);
  .v-expansion-panel {
    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten2);
    }
    .v-expansion-panel-header {
      font-size: 15px;
    }
  }
  .download-app-block {
    font-size: 18px;
  }
  a {
    text-decoration: none;
  }
  .social-icon-container {
    gap: 15px;
    .social-icon {
      width: 50px;
      height: 50px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      gap: 5px;
      .social-icon {
        width: 42px;
        height: 42px;
      }
    }
  }
  a {
    font-size: $font-size-root;
  }
  .credits {
    font-size: 12px;
    a {
      text-decoration: none !important;
    }
  }
}
</style>
<script>
import clickHandler from "@/commons/mixins/clickHandler";
import get from "lodash/get";
import PrivacyLinks from "@/components/navigation/PrivacyLinks.vue";

export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true },
    applicationConfig: { type: Object, required: false }
  },
  components: { PrivacyLinks },
  data() {
    return {
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    }
  }
};
</script>
